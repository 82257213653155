export const URL_PAGES = {
    /* Block 1 */
    obchod: {
        url: 'obchodni-pripady',
        title: 'Obchodní případy',
        order: 10,
    },
    sledovaniZakazek: {
        url: 'sledovani-zakazek',
        title: 'Sledování zakázek',
        order: 20,
    },
    reportInstalaceATechnologie: {
        url: 'report-instalace-technologie',
        title: 'Report instalací a technologie',
        order: 30,
    },
    kalendar: {
        url: 'kalendar',
        title: 'Kalendář',
        order: 35,
    },
    servis: {
        url: 'servis',
        title: 'Servis',
        order: 40,
    },

    /* Block 2 */
    dohledNadOz: {
        url: 'dohled-nad-oz/cinnost-zastupcu',
        title: 'Dohled nad OZ',
        order: 50,
        isBlock2: true,
    },
    financniAnalyza: {
        url: 'financni-analyza/podepsane-smlouvy',
        title: 'Finanční analýza',
        order: 55,
        isBlock2: true,
    },
    prehledCisel: {
        url: 'prehled-cisel/obchod',
        title: 'Přehled čísel',
        order: 60,
        isBlock2: true,
    },
    sklad: {
        url: 'sklad',
        title: 'Sklad',
        order: 70,
        isBlock2: true,
    },

    /* Admin pages */
    uzivatele: {
        url: 'uzivatele',
        title: 'Uživatelé',
        order: 510,
        isAdmin: true,
    },
    logy: {
        url: 'logy',
        title: 'Logy',
        order: 520,
        isAdmin: true,
    },

}
