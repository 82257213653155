import { parseJwt } from './common';
const checkAccessModal = (token, nameModal) => {
  const entitlements = parseJwt(token).entitlements;
  return !!entitlements?.modals?.includes(nameModal);
}

const checkAccessPages = (token, namePage) => {
  const entitlements = parseJwt(token).entitlements;
  return !!entitlements?.pages?.includes(namePage);
}

const getters = {
  getEntitlements: (state) => parseJwt(state.token).entitlements,
  getPagesEntitlements: (state) => parseJwt(state.token)?.entitlements?.pages,
  getModalsEntitlements: (state) => parseJwt(state.token)?.entitlements?.modals,

  // Access to modals
  isObchodniPripadModalAllowed: (state) => checkAccessModal(state.token, 'novaZakazka'),
  isObhlidkovyFormularModalAllowed: (state) => checkAccessModal(state.token, 'obhlidkovyFormular'),
  isKontrolaPVSolModalAllowed: (state) => checkAccessModal(state.token, 'kontrolaPVSol'),
  isSoustavaTechnologieModalAllowed: (state) => checkAccessModal(state.token, 'soustavaTechnologie'),
  ispPofilUzivateleModalAllowed: (state) => checkAccessModal(state.token, 'profilUzivatele'),
  isZmenitHesloUzivateleModalAllowed: (state) => checkAccessModal(state.token, 'zmenitHesloUzivatele'),
  isUpravitPristupModalAllowed: (state) => checkAccessModal(state.token, 'upravitPristup'),
  isStavObchodnihoPripaduAllowed: (state) => checkAccessModal(state.token, 'stavObchodnihoPripadu'),
  isPoznamkyKobchodnimuPripaduAllowed: (state) => checkAccessModal(state.token, 'poznamkyKobchodnimuPripadu'),
  isGDPRRodinnePrislusniciAllowed: (state) => checkAccessModal(state.token, 'gdprRodinnePrislusnici'),
  isSmlouvaDiloAllowed: (state) => checkAccessModal(state.token, 'smlouvaDilo'),
  isAppHistoryEditAllowed: (state) => checkAccessModal(state.token, 'editAppHistory'),

  // Access to pages
  isSledovaniZakazekPageAllowed: (state) => checkAccessPages(state.token, 'sledovaniZakazek'),
  isReportInstalaceATechnologiePageAllowed: (state) => checkAccessPages(state.token, 'reportInstalaceATechnologie'),
  isPrehledCiselPageAllowed: (state) => checkAccessPages(state.token, 'prehledCisel'),
  isDohledNadOzPageAllowed: (state) => checkAccessPages(state.token, 'dohledNadOz'),
  isFinancniAnalyzaPageAllowed: (state) => checkAccessPages(state.token, 'financniAnalyza'),
  isSkladPageAllowed: (state) => checkAccessPages(state.token, 'sklad'),
  isUzivatelePageAllowed: (state) => checkAccessPages(state.token, 'uzivatele'),
  isLogyPageAllowed: (state) => checkAccessModal(state.token, 'logy'),
  isObchodPageAllowed: (state) => checkAccessPages(state.token, 'obchod'),
  isKalendarPageAllowed: (state) => checkAccessPages(state.token, 'kalendar'),
  isServisPageAllowed: (state) => checkAccessPages(state.token, 'servis'),
};


export default {
  getters,
};

