
const state = {
  OZ_LIST: [],
  TPK_LIST: [],
  DESIGNERS_LIST: [],
  INVERTER_MODELS: [],
  BATTERY_STORAGE_LIST: [],
  INVERTERS_FULL: [],
  BATTERY_CAPACITY: [],
  FV_PANELS_FULL: [],
  FV_PANELS_MODELS: [],
  BATTERY_TYPE: [],
  activityCounts: {},
};

const getters = {
  nesplneneAktivityPoTerminuCount: (state) => state.activityCounts.nesplneneAktivityPoTerminuCount || 0,
  nejblizsichAktivitDatesCount: (state) => state.activityCounts.nejblizsichAktivitDatesCount || 0,
  nenaplanovaneAktivityCount: (state) => state.activityCounts.nenaplanovaneAktivityCount || 0,
  nesplneneAktivityCount: (state) => state.activityCounts.nesplneneAktivityCount || 0,
};

const actions = {

};

const mutations = {
  activityCounts(state, payload) {
    state.activityCounts = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
