import axios from "axios";
const TOKEN_KEY = 'jwt-token';
import { parseJwt } from './common';
import Entitlements from './entitlements.module'
import {OPTIONS_ACCESS_BY_OZ} from "@/constants/form.constants";

const state = {
  token: localStorage.getItem(TOKEN_KEY),
  userId: null,
  userInfo: {},
};

const getters = {
  token: (state) => state.token,
  isAuthenticated: (_, getters) => {
    if (getters.token === "undefined") {
      return false;
    }
    return !!getters.token;
  },
  userLogin: (state) => parseJwt(state.token).login,
  userInfo: (state) => state.userInfo,
  isAdmin: (state) => parseJwt(state.token).login === 'a.degterov',
  getUserNumberOZ: (state) => state.userInfo?.numberOZ || '',
  isHaveActiveOzUsersGroup: (state) => !!state.userInfo?.ozUserGroupIds?.length,
  isHaveDeactivatedOzUsersGroup: (state) => !!state.userInfo?.ozUserGroupDeactivatedIds?.length,
  isCanSeeAllZakazky: (state) => state.userInfo?.accessByOz === OPTIONS_ACCESS_BY_OZ.ALL,
  isCanSeeOnlyForOwn: (state) => (state.userInfo?.accessByOz === OPTIONS_ACCESS_BY_OZ.ONLY_OWN) || (state.userInfo?.numberOZ && !state.userInfo?.accessByOz),

  getUserNamesAndTitles: (state) => {
    const {titleBeforeName, titleAfterName, name, lastName} = state.userInfo;
    const joinedName = [titleBeforeName, name, lastName].filter(n => n).join(' ');
    return titleAfterName ? `${joinedName}, ${titleAfterName}` : joinedName;
  },
  getUserFioTitles: (state) => {
    const {titleBeforeName, titleAfterName, name, lastName} = state.userInfo;
    let joinedName = [lastName, name].filter(n => n).join(' ');
    joinedName += titleBeforeName ? `, ${titleBeforeName}` : '';
    joinedName += titleAfterName ? `, ${titleAfterName}` : '';
    return joinedName;
  },
  ...Entitlements.getters
};

const actions = {
  async LogInUser({ commit }, payload) {
    try {
      const url = `/user/login`;
      const { data = {accessToken: null} } = await axios.post(url, {...payload});
      const userId = parseJwt(data.accessToken).userId;

      commit('setToken', data.accessToken);
      commit('setUserId', userId);
    } catch (e) {
      throw new Error()
    }
  },
  async getUserInfo({ commit }) {
    if (!state.userId) {
      const userId = parseJwt(state.token).userId;
      commit('setUserId', userId);
    }

    const url = `/user/getUserInfo`;
    const { data } = await axios.get(url, { params: {userId: state.userId}});
    commit('setUserInfo', data.data.user);
  }
};

const mutations = {
  setToken(state, token) {
    state.token = token;
    localStorage.setItem(TOKEN_KEY, state.token);
  },
  setUserId(state, userId) {
    state.userId = userId;
  },
  setUserInfo(state, user) {
    state.userInfo = user;
  },
  logout(state) {
    state.token = null;
    state.userId = null;
    state.userInfo = {};
    state.numberOZ = null;
    localStorage.removeItem(TOKEN_KEY);
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
