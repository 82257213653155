<template>
  <header id="main-header">
    <b-col cols="12" class="p-0">
      <b-navbar toggleable type="light">
        <b-navbar-brand>Plán nejbližších aktivit</b-navbar-brand>

        <b-navbar-toggle target="navbar-toggle-collapse">
          <template #default="{ expanded }">
            <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
            <b-icon v-else icon="chevron-bar-down"></b-icon>
          </template>
        </b-navbar-toggle>

        <b-collapse id="navbar-toggle-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-dropdown-text class="text-nowrap text-black">Login: <span class="font-weight-bold">{{ userInfo.login }}</span></b-dropdown-text>
            <b-dropdown-item href="#" @click.prevent="logout">Odhlásit se</b-dropdown-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-col>
  </header>
</template>


<script>

import router from "@/router";
import {URL_PAGES} from "@/constants/url.constants";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "NavBar",
  props: {
    routeName: {type: String},
  },
  computed: {
    ...mapGetters(['userInfo', 'getPagesEntitlements', 'getUserNamesAndTitles', 'getModalsEntitlements', 'getUserNumberOZ',
      'nesplneneAktivityPoTerminuCount', 'nejblizsichAktivitDatesCount', 'nenaplanovaneAktivityCount', 'nesplneneAktivityCount',
    ]),

  },
  data() {
    return {
      allowedPagesBlock1: [],
      allowedPagesBlock2: [],
      allowedAdminPages: [],
    }
  },
  methods: {
    ...mapActions(["getUserInfo"]),
    async logout() {
      try {
        const url = `/user/logout`;
        await axios.get(url);
        await this.$store.commit('logout');
        await router.push('/login');
      } catch (e) {
        console.log(e)
      }
    },
  },
  async mounted() {
    await this.getUserInfo();
    const entitlementsPages = this.getPagesEntitlements || [];
    const entitlementsModals = this.getModalsEntitlements || [];
    entitlementsPages.forEach(ent => {
      if (URL_PAGES[ent]) {
        if (URL_PAGES[ent].isAdmin) {
          return this.allowedAdminPages.push(URL_PAGES[ent]);
        } else if (URL_PAGES[ent].isBlock2) {
          return this.allowedPagesBlock2.push(URL_PAGES[ent]);
        }
        this.allowedPagesBlock1.push(URL_PAGES[ent]);
      } else if (ent === 'administrator') {
        if (entitlementsModals.includes('logy')) {  // logy page isn't a modal, but it saves as a modal in entitlements
          return this.allowedAdminPages.push(URL_PAGES['logy']);
        }
      }
    });
    this.allowedPagesBlock1 = _.sortBy(this.allowedPagesBlock1, ['order']);
    this.allowedPagesBlock2 = _.sortBy(this.allowedPagesBlock2, ['order']);
    this.allowedAdminPages = _.sortBy(this.allowedAdminPages, ['order']);
  },
  components: {},
};
</script>

<style scoped>

</style>