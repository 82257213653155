export const MESSAGES = {
    SELECT_FROM_LIST: 'Vyberte prosím hodnotu ze seznamu',
}

export const MONTH_NAMES = [
  'leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'
];

export const TYPE_OF_WORKS_PROJEKCE = [
  {key: 'noveZakazkyRDBD', text: 'Nové zakázky RD, BD', class: 'font-weight-bold'},
  {key: 'podepsanoSmluvRDBD', text: 'Podepsáno smluv, nové / light / rozšíření'},
  {key: 'PVSOL', text: 'PV*SOL, nové / rozšíření'},
  {key: 'completionDatePD', text: 'Komplety PD (KM+JPS+TZd), nové / rozšíření'},
  {key: 'dateSigningEH', text: 'EH (nové zakázky)'},
  {key: 'reqConnectDistrib', text: 'Odesláno žádostí o smlouvu s distributorem'},
  {key: 'sendingSignedContractPD', text: 'Odesláno podepsaných smluv s distributorem'},
  {key: 'sendingDateNZU', text: 'Odesláno žádostí o dotace na NZÚ'},

  {key: 'dokonceniRealizaceRDBD', text: 'Dokončení realizace RD, BD', class: 'font-weight-bold'},
  {key: 'PVSOLaktualizace', text: 'PV*SOL (aktualizace)'},
  {key: 'completingDateDSPS', text: 'Komplety DSPS (JPS+TZr)'},
  {key: 'updateDateEH', text: 'EH (aktualizace)'},
  {key: 'deliveryElectricalInspectionDate', text: 'Dodání revize elektro'},
  {key: 'sendingParallelConnectionDate', text: 'Odesláno žádostí o PPP'},
  {key: 'connectionDate', text: 'Protokol o PPP'},
  {key: 'sendingReimbursementDate', text: 'Odesláno žádosti o proplacení dotace'},
  {key: 'reimbursementTermination', text: 'Proplacení dotace, ukončení zakázky'},
]

export const TYPE_OF_WORKS_OBCHOD = [
  {key: 'lineRD', text: 'Rodinné domy (RD)', class: 'font-weight-bold'},
  {key: 'podepsanoSmluvRD', text: 'Podepsáno smluv, nové / light / rozšíření'},
  {key: 'prumernaHodnotaRD', text: 'Průměrná hodnota'},
  {key: 'soucetHodnotRD', text: 'Součet hodnot'},
  {key: 'prohryRD', text: 'Prohry'},

  {key: 'lineBD', text: 'Bytové domy (BD)', class: 'font-weight-bold'},
  {key: 'podepsanoSmluvBD', text: 'Podepsáno smluv, nové / rozšíření'},
  {key: 'prumernaHodnotaBD', text: 'Průměrná hodnota'},
  {key: 'soucetHodnotBD', text: 'Součet hodnot'},
  {key: 'prohryBD', text: 'Prohry'},

  {key: 'lineFirmy', text: 'FIRMY', class: 'font-weight-bold'},
  {key: 'podepsanoSmluvFirmy', text: 'Podepsáno smluv, nové / rozšíření'},
  {key: 'prumernaHodnotaFirmy', text: 'Průměrná hodnota'},
  {key: 'soucetHodnotFirmy', text: 'Součet hodnot'},
  {key: 'prohryFirmy', text: 'Prohry'},
  // {key: 'vyhryRDnove', text: 'Výhry RD (nové)'},
  // {key: 'vyhryRDrozsireni', text: 'Výhry RD (rozšíření)'},
  // {key: 'vyhryRDnzuLight', text: 'Výhry RD (NZU Light)'},

]

export const TYPE_OF_WORKS_REALIZACE = [
  {key: 'lineRD', text: 'Rodinné domy (RD)', class: 'font-weight-bold'},
  {key: 'panelyVsechTypuRD', text: 'Panely (všech typů)'},
  {key: 'stridaceSitoveVsechTypuRD', text: 'Střídače síťové (všech typů)'},
  {key: 'stridaceHibridniVsechTypuRD', text: 'Střídače hybridní (všech typů)'},
  {key: 'baterieVsechTypuRD', text: 'Baterie (všech typů)'},
  {key: 'doconcenoInstalaciRD', text: 'Dokončeno instalací (vč. podepsání protokolů)'},
  {key: 'implemStartDateRD', text: 'Zahájeno nových instalací'},

  {key: 'lineBD', text: 'Bytové domy (BD)', class: 'font-weight-bold'},
  {key: 'panelyVsechTypuBD', text: 'Panely (všech typů)'},
  {key: 'stridaceSitoveVsechTypuBD', text: 'Střídače síťové (všech typů)'},
  {key: 'stridaceHibridniVsechTypuBD', text: 'Střídače hybridní (všech typů)'},
  {key: 'baterieVsechTypuBD', text: 'Baterie (všech typů)'},
  {key: 'doconcenoInstalaciBD', text: 'Dokončeno instalací (vč. podepsání protokolů)'},
  {key: 'implemStartDateBD', text: 'Zahájeno nových instalací'},

  {key: 'lineFirmy', text: 'FIRMY', class: 'font-weight-bold'},
  {key: 'firmy1', text: ''},
  {key: 'firmy2', text: ''},
  {key: 'firmy3', text: ''},
  {key: 'firmy4', text: ''},
  {key: 'firmy5', text: ''},
]

export const FILTER_TYPES = {
  NAZEV_V_ADRESARE: 'nazevVadresare',
  OBJECT_OBCHODNIHO_PRIPADU: 'object',
  STAV_OBCHODNIHO_PRIPADU: 'stav',
  KRAJ_OBCHODNIHO_PRIPADU: 'kraj',
  OZ_USERS_ACTIVE: 'ozUsersActive',
  OZ_USERS_DEACTIVATED: 'ozUsersDisabled',
  MANAGER_KOORDINATOR: 'managerKoordinator',
  MULTIFILTR: 'multifiltr',
  NENAPLANOVANE_AKTIVITY: 'nenaplanovaneAktivity',
  NESPLNENE_AKTIVITY: 'nesplneneAktivity',
}

const FILTER_TEXTS = {
  NAZEV_V_ADRESARE: 'Název adresáře v zakázkách',
  OBJECT_OBCHODNIHO_PRIPADU: 'Objekt obchodního případu',
  STAV_OBCHODNIHO_PRIPADU: 'Stav obchodního případu',
  KRAJ_OBCHODNIHO_PRIPADU: 'Kraj obchodního případu',
  OZ_USERS_ACTIVE: 'Obchodní zástupci (aktivní)',
  OZ_USERS_DEACTIVATED: 'Obchodní zástupci (deaktivované)',
  MANAGER_KOORDINATOR: 'Projektový manažer / koordinátor',
  MULTIFILTR: 'Multifiltr',
  NENAPLANOVANE_AKTIVITY: 'Nenaplánované aktivity',
  NESPLNENE_AKTIVITY: 'Nesplněné aktivity',
}

export const LIST_OBCH_PRIPAD_FILTER_TYPES = [
  { value: FILTER_TYPES.NAZEV_V_ADRESARE, text: FILTER_TEXTS.NAZEV_V_ADRESARE },
  { value: FILTER_TYPES.OBJECT_OBCHODNIHO_PRIPADU, text: FILTER_TEXTS.OBJECT_OBCHODNIHO_PRIPADU },
  { value: FILTER_TYPES.STAV_OBCHODNIHO_PRIPADU, text: FILTER_TEXTS.STAV_OBCHODNIHO_PRIPADU },
  { value: FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU, text: FILTER_TEXTS.KRAJ_OBCHODNIHO_PRIPADU },
  { value: FILTER_TYPES.OZ_USERS_ACTIVE, text: FILTER_TEXTS.OZ_USERS_ACTIVE },
  { value: FILTER_TYPES.OZ_USERS_DEACTIVATED, text: FILTER_TEXTS.OZ_USERS_DEACTIVATED },
  { value: FILTER_TYPES.MANAGER_KOORDINATOR, text: FILTER_TEXTS.MANAGER_KOORDINATOR },
  { value: FILTER_TYPES.MULTIFILTR, text: FILTER_TEXTS.MULTIFILTR },
]

export const LIST_CALENDAR_FILTER_TYPES = [
  { value: FILTER_TYPES.NAZEV_V_ADRESARE, text: FILTER_TEXTS.NAZEV_V_ADRESARE },
  { value: FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU, text: FILTER_TEXTS.KRAJ_OBCHODNIHO_PRIPADU },
  { value: FILTER_TYPES.OZ_USERS_ACTIVE, text: FILTER_TEXTS.OZ_USERS_ACTIVE },
  { value: FILTER_TYPES.OZ_USERS_DEACTIVATED, text: FILTER_TEXTS.OZ_USERS_DEACTIVATED },
]

export const LIST_SLED_ZAKAZEK_FILTER_TYPES = [
  { value: FILTER_TYPES.NAZEV_V_ADRESARE, text: FILTER_TEXTS.NAZEV_V_ADRESARE },
  { value: FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU, text: FILTER_TEXTS.KRAJ_OBCHODNIHO_PRIPADU },
  { value: FILTER_TYPES.OZ_USERS_ACTIVE, text: FILTER_TEXTS.OZ_USERS_ACTIVE },
  { value: FILTER_TYPES.OZ_USERS_DEACTIVATED, text: FILTER_TEXTS.OZ_USERS_DEACTIVATED },
  { value: FILTER_TYPES.MANAGER_KOORDINATOR, text: FILTER_TEXTS.MANAGER_KOORDINATOR },
]

export const LIST_REPORT_INSTALACI_FILTER_TYPES = [
  { value: FILTER_TYPES.NAZEV_V_ADRESARE, text: FILTER_TEXTS.NAZEV_V_ADRESARE },
  { value: FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU, text: FILTER_TEXTS.KRAJ_OBCHODNIHO_PRIPADU },
  { value: FILTER_TYPES.OZ_USERS_ACTIVE, text: FILTER_TEXTS.OZ_USERS_ACTIVE },
  { value: FILTER_TYPES.OZ_USERS_DEACTIVATED, text: FILTER_TEXTS.OZ_USERS_DEACTIVATED },
  { value: FILTER_TYPES.MANAGER_KOORDINATOR, text: FILTER_TEXTS.MANAGER_KOORDINATOR },
]

export const LIST_SERVIS_FILTER_TYPES = [
  { value: FILTER_TYPES.NAZEV_V_ADRESARE, text: FILTER_TEXTS.NAZEV_V_ADRESARE },
  { value: FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU, text: FILTER_TEXTS.KRAJ_OBCHODNIHO_PRIPADU },
]

export const LIST_UKOLOVANI_OZ_FILTER_TYPES = [
  { value: FILTER_TYPES.NENAPLANOVANE_AKTIVITY, text: FILTER_TEXTS.NENAPLANOVANE_AKTIVITY },
  { value: FILTER_TYPES.NESPLNENE_AKTIVITY, text: FILTER_TEXTS.NESPLNENE_AKTIVITY },
]

export const TRIENERGO_ASSISTENT_EMAIL= 'trienergo.asistent@gmail.com';

export const BCC_ASISTENT_EMAIL = 'trienergo.asistent@gmail.com';

export const USER_TYPES = {
  OZ: 'ozUser',
  P_MANAGER: 'projManager',
  P_KOORDINATOR: 'projKoordinator',
  PROJEKTANT: 'projektant',
  PV_SOL: 'pvSol',
}

export const USER_TYPES_LIST = [
  { text: 'Obchodní zástupce (OZ)', value: USER_TYPES.OZ },
  { text: 'Projektový manažer', value: USER_TYPES.P_MANAGER },
  { text: 'Projektový koordinátor', value: USER_TYPES.P_KOORDINATOR },
  { text: 'Projektant', value: USER_TYPES.PROJEKTANT },
  { text: 'PV*SOL', value: USER_TYPES.PV_SOL },
]

export const KATEGORIE_OZ = {
  JUNIOR: 'junior',
  SENIOR: 'senior',
  INDIVIDUAL: 'individual',
}

export const KATEGORIE_OZ_LIST = [
  { value: KATEGORIE_OZ.JUNIOR, text: KATEGORIE_OZ.JUNIOR },
  { value: KATEGORIE_OZ.SENIOR, text: KATEGORIE_OZ.SENIOR },
  { value: KATEGORIE_OZ.INDIVIDUAL, text: KATEGORIE_OZ.INDIVIDUAL },
];

export const OPTIONS_ACCESS_BY_OZ = {
  ONLY_OWN: 'onlyForOwn',
  ALL: 'all',
  FOR_SELECTED_GROUP: 'forSelectedGroup',
}

export const VIDI_ZAKAZKY = [
  { value: OPTIONS_ACCESS_BY_OZ.ONLY_OWN, text: 'jen své' },
  { value: OPTIONS_ACCESS_BY_OZ.FOR_SELECTED_GROUP, text: 'vybrané skupiny' },
  { value: OPTIONS_ACCESS_BY_OZ.ALL, text: 'vše' },
];

export const OBJECT_TYPE = {
  RD: 'familyHouse',
  BD: 'apartmentBuilding',
  FIRM: 'firm',
}

export const BANK_CODES = {
  '0100': 'Komerční banka, a.s.',
  '0300': 'ČSOB, a.s.',
  '0600': 'MONETA Money Bank, a.s.',
  '0710': 'Česká národní banka',
  '0800': 'Česká spořitelna, a.s.',
  '2010': 'Fio banka, a.s.',
  '2020': 'MUFG Bank (Europe) N.V. Prague Branch',
  '2060': 'Citfin, spořitelní družstvo',
  '2070': 'TRINITY BANK a.s.',
  '2100': 'Hypoteční banka, a.s.',
  '2200': 'Peněžní dům, spořitelní družstvo',
  '2220': 'Artesa, spořitelní družstvo',
  '2250': 'Banka CREDITAS a.s.',
  '2260': 'NEY spořitelní družstvo',
  '2275': 'Podnikatelská družstevní záložna',
  '2600': 'Citibank Europe plc, organizační složka',
  '2700': 'UniCredit Bank Czech Republic and Slovakia, a.s.',
  '3030': 'Air Bank a. s.',
  '3050': 'BNP Paribas Personal Finance SA, odštěpný závod',
  '3060': 'PKO BP S.A., Czech Branch',
  '3500': 'ING Bank N.V.',
  '4000': 'Max banka a.s.',
  '4300': 'Národní rozvojová banka, a.s.',
  '5500': 'Raiffeisenbank a.s.',
  '5800': 'J&T Banka, a.s.',
  '6000': 'PPF banka a.s.',
  '6100': 'Raiffeisenbank a.s.',
  '6200': 'COMMERZBANK Aktiengesellschaft, pobočka Praha',
  '6210': 'mBank S.A., organizační složka',
  '6300': 'BNP Paribas S.A., pobočka Česká republika',
  '6700': 'Všeobecná úverová banka a.s., pobočka Praha',
  '7910': 'Deutsche Bank A.G. Filiale Prag',
  '7950': 'Raiffeisen stavební spořitelna a.s.',
  '7960': 'ČSOB Stavební spořitelna, a.s.',
  '7970': 'Moneta Stavební spořitelna a.s.',
  '7990': 'Modrá pyramida stavební spořitelna, a.s.',
  '8030': 'Volksbank Raiffeisenbank Nordoberpfalz eG pobočka Cheb',
  '8040': 'Oberbank AG pobočka Česká republika',
  '8060': 'Stavební spořitelna České spořitelny, a.s.',
  '8090': 'Česká exportní banka, a.s.',
  '8150': 'HSBC Continental Europe, Czech Republic',
  '8190': 'Sparkasse Oberlausitz-Niederschlesien',
  '8198': 'FAS finance company s.r.o.',
  '8199': 'MoneyPolo Europe s.r.o.',
  '8200': 'PRIVAT BANK AG der Raiffeisenlandesbank Oberösterreich v České republice',
  '8220': 'Payment Execution s.r.o.',
  '8230': 'ABAPAY s.r.o.',
  '8240': 'Družstevní záložna Kredit, v likvidaci',
  '8250': 'Bank of China (CEE) Ltd. Prague Branch',
  '8255': 'Bank of Communications Co., Ltd., Prague Branch odštěpný závod',
  '8265': 'Industrial and Commercial Bank of China Limited, Prague Branch, odštěpný závod',
  '8270': 'Fairplay Pay s.r.o.',
  '8280': 'B-Efekt a.s.',
  '8293': 'Mercurius partners s.r.o.',
  '8299': 'BESTPAY s.r.o.',
  '8500': 'Ferratum Bank plc',
}
