import { extend } from 'vee-validate/dist/vee-validate.full';
import { MESSAGES } from '@/constants/form.constants';

extend('mustBeInList', {
    params: ['list'],
    validate: (value, { list }) => {
        return list.includes(value);
    },
    message: MESSAGES.SELECT_FROM_LIST
});

extend('kodZakazky', {
    validate: value => {
        const regexPattern = /^[0-9]{7}[0A-NP-Z]$/;
        return regexPattern.test(value);
    },
    message: 'Kód případu musí obsahovat 8 znaků:<br>7 číslic na začátku a na konci 0 /nulu/<br>nebo písmeno F, L, R'
});

extend('password', {
    validate: value => {
        const regexPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+|[\]{};':"\-\\,.<>/?])[A-Za-z\d!@#$%^&*()_+|[\]{};':"\-\\,.<>/?]{8,}$/;
        return regexPattern.test(value);
    },
    message: 'Heslo musí obsahovať aspoň jedno číslo, veľké písmeno, malé písmeno a špeciálny znak'
});

extend('bankovniSpojeni', {
    validate: value => {
        const regexPattern = /^[\d-]+\/\d{4}$/;
        return regexPattern.test(value);
    },
    message: 'Může obsahovat čísla a pomlčku<br>následovanou lomítkem a 4 číslicemi'
});