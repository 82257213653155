import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import('@/views/HomePage.vue'),
    meta: {requiresAuth: true, title: 'Plán nejbližších aktivit'},
  },
  {
    path: "/login",
    name: "Přihlášení",
    component: () => import('@/views/LoginPage.vue'),
    meta: { guest: true, title: "Přihlášení" },
  },
  {
    path: "*",
    beforeEnter(to, from, next) {
      next("/")
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BACKEND_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});

router.afterEach((to) => {
  document.title = to.meta?.title ? `CRM Trienergo mobile — ${to.meta.title}` : 'CRM Trienergo mobile — FVE creator'; // Используйте заголовок из метаданных или установите заголовок по умолчанию
});

export default router;
